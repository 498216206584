// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component3Page() {
//useNavigate const navigate = useNavigate()


const [content,setContent]= useState( {} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
useEffect(()=>{  action_event_447_bloc4()   },[])

const action_447 =    ({TemplateConsent,setTemplateConsent,postTemplateConsent})=> {    
        try { 
        

window.alert("TEST OK")
}
        catch (error) {
        console.error(error);
        }
      };  

const action_event_447_bloc4 =  async(event)=>{

                            
                           
              
                          await action_447( { event }) 
              
                          };  

const action_453 =    ({Etat,setEtat,postEtat})=> {    
        try { 
        

setEtat(!Etat)
}
        catch (error) {
        console.error(error);
        }
      };  

const setEtatbloc4_1_1 =(value)=> setInternal("etat1",value);

const action_event_453_bloc4_1_1 =  async(event)=>{

                            
                           var Etat = content["etat1"];
              
                          await action_453( { Etat,event, setEtat:setEtatbloc4_1_1  }) 
              
                          };  

const action_454 =    ({Etat,setEtat,postEtat})=> {    
        try { 
        

setEtat(Etat!=="vert" ? "vert":"rose")
}
        catch (error) {
        console.error(error);
        }
      };  

const setEtatbloc4_1_2 =(value)=> setInternal("etat2",value);

const action_event_454_bloc4_1_2 =  async(event)=>{

                            
                           var Etat = content["etat2"];
              
                          await action_454( { Etat,event, setEtat:setEtatbloc4_1_2  }) 
              
                          };  

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-5/6 flex flex-row justify-between items-center bg-zinc-700 bg-primary_color safearea"}     > <img  id='bloc0_0'  className={ "w-10 h-10 rounded-xl m-2   object-cover"}  src="/profiles/ploy6/user.png"   alt="undefined" />
          <div id='bloc0_1'  className={ "w-96 flex justify-between font-bold text-base text-white bg-red"}     > <div id='bloc0_1_0'       >{`Qui suis-je`} </div>
            <div id='bloc0_1_1'       >{`La Facia`} </div>
            <div id='bloc0_1_2'       >{`Les soins`} </div>
            <div id='bloc0_1_3'       >{`Tarifs`} </div></div>
          <div id='bloc0_2'  className={ "m-4 rounded-full py-2 px-8  z-20 font-bold text-primary_color bg-white"}     >{`Contact`} </div></div><div id='bloc2'  className={ "w-full py-4 grid  md:grid-cols-2   grid-cols-1 gap-4 backgroundColor=#F8F8F8 "}   style = { {"backgroundColor":"#F8F8F8"}}   > <div id='bloc2_0'  className={ "flex flex-row w-full justify-center md:justify-end  mt-10"}     > <img  id='bloc2_0_0'  className={ " w-full  md:w-3/4  h-50    object-cover"}  src="https://fs.appisyou.com/apps/konjaque/panier-removebg-preview1708331093664.png"   alt="undefined" /></div>
          <div id='bloc2_1'  className={ "flex flex-col p-4 py-10 justify-center  items-center  md:justify-center md:items-start "}     > <div id='bloc2_1_0'  className={ "font-size=3rem  py-2"}   style = { {"font-size":"3rem"}}   >{`VOTRE PANIER`} </div>
            <div id='bloc2_1_1'  className={ " flex md:flex-row flex-col justify-center items-center py-4"}     > <div id='bloc2_1_1_0'  className={ "font-size=6rem line-height=6rem"}   style = { {"font-size":"6rem","line-height":"6rem"}}   >{`100%`} </div>
              <div id='bloc2_1_1_1'  className={ "flex md:flex-col text-xs justify-start items-start px-3 font-size=1.5rem line-height=1.5rem"}   style = { {"font-size":"1.5rem","line-height":"1.5rem"}}   > <div id='bloc2_1_1_1_0'       >{`BIO.`} </div>
                <div id='bloc2_1_1_1_1'       >{`LOCAL.`} </div>
                <div id='bloc2_1_1_1_2'       >{`DE SAISON`} </div></div></div>
            <div id='bloc2_1_2'       > </div>
            <div id='bloc2_1_3'  className={ "font-size=3rem    pt-2 pb-6"}   style = { {"font-size":"3rem"}}   >{`CHAQUE SEMAINE`} </div>
            <div id='bloc2_1_4'  className={ "shadow bg-orange-400 bg-primary_color p-2 flex flex-row text-white justify-center items-center font-size=2rem px-6 letter-spacing=0.4vw bottom=-1.5rem"}   style = { {"font-size":"2rem","letter-spacing":"0.4vw","bottom":"-1.5rem"}}   > <div id='bloc2_1_4_0'       >{`s'abonner`} </div>
              <FontAwesomeIcon  id='bloc2_1_4_1'  icon={ fa.faArrowRight}      className={ "p-1"}  /></div></div></div><div id='bloc3'  className={ "w-full bg-zinc-100  p-6 flex  flex-col justify-center items-start text-zinc-900 py-8 text-primary_color"}     > <div id='bloc3_0'  className={ "flex flex-col justify-start items-start md:grid grid-cols-3 "}     > <div id='bloc3_0_0'  className={ " font-bold bg-black text-white text-lg text-left inline p-4 rounded-full px-6 "}     >{`Créer une app mobile`} </div>
            <div id='bloc3_0_1'  className={ "flex flex-col items-start"}     > <div id='bloc3_0_1_0'  className={ "font-bold"}     >{`Titre`} </div>
              <div id='bloc3_0_1_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`Utilisez nos templates sur mesure et personnalisables ainsi que des milliers de fonctionnalités de design qui vous permettront de donner vie à vos idées. Vous pouvez également ajouter votre propre code pour un controle total du design de votre site.`} </div></div>
            <div id='bloc3_0_2'  className={ "flex flex-col items-start"}     > <div id='bloc3_0_2_0'  className={ "font-bold"}     >{`Titre`} </div>
              <div id='bloc3_0_2_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`Avec AppisYou, vous pouvez créer vous-même un site Web gratuit et professionnel. Vous pouvez commencer avec un modèle design et le personnaliser, ou obtenir un site créé pour vous sur-mesure. Bénéficiez d'une solution tout-en-un : hébergement Web fiable et gratuit, sécurité maximale, référencement puissant et assistance 24h/24.`} </div></div>
            <div id='bloc3_0_3'  className={ "flex flex-col items-start"}     > <div id='bloc3_0_3_0'  className={ "font-bold"}     >{`Titre`} </div>
              <div id='bloc3_0_3_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{` En créant un site avec notre créateur de site internet, vous profitez de tous les outils nécessaires pour lancer votre acitivité en ligne comme vous le souhaitez.`} </div></div></div></div><div id='bloc4'  className={ "w-full flex flex-col justify-center items-center w-full flex flex-col justify-center items-center py-4 safearea-top"}    onStart = { action_event_447_bloc4} > <div id='bloc4_0'  className={ "h-40  w-full bg-orange-400  bg-primary_color"}     > </div>
          <div id='bloc4_1'  className={ "   -mt-32 -mb-32    md:-my-20 z-10 flex flex-col items-center justify-center md:flex-row"}     > <div id='bloc4_1_0'  className={ "bg-white  w-40 h-40 z-10 rounded-xl p-2"}     > <img  id='bloc4_1_0_0'  className={ "w-full h-full rounded-full opacity-50   object-cover"}  src="undefined"   alt="undefined" /></div>
            <div id='bloc4_1_1'  className={ " py-2 bg-zinc-900 flex flex-row items-center rounded-lg border-white"}    onClick = { action_event_453_bloc4_1_1} > <FontAwesomeIcon  id='bloc4_1_1_0'  icon={ fa.faDownload}      className={ "w-8 h-8 text-white"}  />
              <div id='bloc4_1_1_1'  className={ "px-1 flex flex-col justify-center items-center  "}     > <div id='bloc4_1_1_1_0'  className={ "text-white text-xs"}     >{`Install with`} </div>
                <div id='bloc4_1_1_1_1'  className={ "text-white text-xl font-extrabold"}     >{`AppIsYou`} </div></div></div>
            <div id='bloc4_1_2'  className={ " py-2 bg-zinc-900 flex flex-row items-center rounded-lg border-white"}    onClick = { action_event_454_bloc4_1_2} > <FontAwesomeIcon  id='bloc4_1_2_0'  icon={ fa.faDownload}      className={ "w-8 h-8 text-white"}  />
              <div id='bloc4_1_2_1'  className={ "px-1 flex flex-col justify-center items-center  "}     > <div id='bloc4_1_2_1_0'  className={ "text-white text-xs"}     >{`Install with`} </div>
                <div id='bloc4_1_2_1_1'  className={ "text-white text-xl font-extrabold"}     >{`AppIsYou`} </div></div></div></div>
          <div id='bloc4_2'  className={ "h-40  w-full bg-blue-200 bg-secondary_color"}     > </div></div><div id='bloc5'  className={ "w-full bg-black bg-secondary_color p-6 flex flex-col justify-center items-center text-white"}     > <div id='bloc5_0'  className={ "text-4xl font-bold  my-2"}     >{`Des templates de sites web qereteshtconçus pour vous démarquer`} </div>
          <div id='bloc5_1'  className={ "flex flex-col justify-start "}     > <div id='bloc5_1_0'  className={ " my-2 text-lg  max-w-5/6"}     >{`Gagnez du temps et choisissez l'un de nos 900 templates gratuits, personnalisables et élaborés stratégiquement pour chaque domaine d'activité. Vous pouvez également choisir d'utiliser un canvas vierge pour commencer à créer votre site.  `} </div>
            <div id='bloc5_1_1'  className={ "flex flex-col justify-center items-center"}     > <div id='bloc5_1_1_0'  className={ "p-2"}     > </div>
              <div id='bloc5_1_1_1'  className={ "font-bold  border-white text-left inline p-4"}     > <div id='bloc5_1_1_1_0'  className={ "font-bold  border-white text-left inline p-4"}     >{`PORTFOLIO`} </div>
                <FontAwesomeIcon  id='bloc5_1_1_1_1'  icon={ fa.faLongArrowRight}        /></div>
              <div id='bloc5_1_1_2'  className={ "bg-white p-4  text-lg rounded-full text-black"}     >{`Voir les templates`} </div></div></div></div><div id='bloc6'  className={ "w-full p-2"}     > <div id='bloc6_0'  className={ "w-full flex flex-col p-8 border-zinc-400 border-2 rounded-xl shadow-xl my-2 border-secondary_color text-secondary_color"}     > <div id='bloc6_0_0'  className={ "flex flex-row w-full items-center justify-center"}     > <FontAwesomeIcon  id='bloc6_0_0_0'  icon={ fa.faBoreHole}      className={ "p-2 w-6 h-6"}  />
              <div id='bloc6_0_0_1'  className={ "text-xl font-bold p-2 grow text-left"}     > </div>
              <FontAwesomeIcon  id='bloc6_0_0_2'  icon={ fa.faAngleDown}      className={ "text-2xl opacity-50 duration-400"}  /></div>
            <div id='bloc6_0_1'  className={ "text-left duration-300 overflow-hidden w-full"}     > </div></div></div><div id='bloc7'  className={ "w-full p-2"}     > <div id='bloc7_0'  className={ "w-full flex flex-col p-8 border-zinc-400 border-2 rounded-xl shadow-xl my-2 border-secondary_color text-secondary_color"}     > <div id='bloc7_0_0'  className={ "flex flex-row w-full items-center justify-center"}     > <FontAwesomeIcon  id='bloc7_0_0_0'  icon={ fa.faBoreHole}      className={ "p-2 w-6 h-6"}  />
              <div id='bloc7_0_0_1'  className={ "text-xl font-bold p-2 grow text-left"}     > </div>
              <FontAwesomeIcon  id='bloc7_0_0_2'  icon={ fa.faAngleDown}      className={ "text-2xl opacity-50 duration-400"}  /></div>
            <div id='bloc7_0_1'  className={ "text-left duration-300 overflow-hidden w-full"}     > </div></div></div><div id='bloc8'  className={ "w-full p-2"}     > <div id='bloc8_0'  className={ "w-full flex flex-col p-8 border-zinc-400 border-2 rounded-xl shadow-xl my-2 border-secondary_color text-secondary_color"}     > <div id='bloc8_0_0'  className={ "flex flex-row w-full items-center justify-center"}     > <FontAwesomeIcon  id='bloc8_0_0_0'  icon={ fa.faBoreHole}      className={ "p-2 w-6 h-6"}  />
              <div id='bloc8_0_0_1'  className={ "text-xl font-bold p-2 grow text-left"}     > </div>
              <FontAwesomeIcon  id='bloc8_0_0_2'  icon={ fa.faAngleDown}      className={ "text-2xl opacity-50 duration-400"}  /></div>
            <div id='bloc8_0_1'  className={ "text-left duration-300 overflow-hidden w-full"}     > </div></div></div><div id='bloc9'  className={ "w-full p-2"}     > <div id='bloc9_0'  className={ "w-full flex flex-col p-8 border-zinc-400 border-2 rounded-xl shadow-xl my-2 border-secondary_color text-secondary_color"}     > <div id='bloc9_0_0'  className={ "flex flex-row w-full items-center justify-center"}     > <FontAwesomeIcon  id='bloc9_0_0_0'  icon={ fa.faBoreHole}      className={ "p-2 w-6 h-6"}  />
              <div id='bloc9_0_0_1'  className={ "text-xl font-bold p-2 grow text-left"}     > </div>
              <FontAwesomeIcon  id='bloc9_0_0_2'  icon={ fa.faAngleDown}      className={ "text-2xl opacity-50 duration-400"}  /></div>
            <div id='bloc9_0_1'  className={ "text-left duration-300 overflow-hidden w-full"}     > </div></div></div><div id='bloc10'  className={ "w-full bg-black bg-primary_color flex flex-row justify-center"}     > <div id='bloc10_0'  className={ "    p-2 h  grid  grids-cols-1  md:grid-cols-2  lg:grids-cols-3  xl:grid-cols-4 gap-1 text-white"}     > <div id='bloc10_0_0'  className={ " flex flex-col items-start p-4"}     > <div id='bloc10_0_0_0'  className={ "text-xl font-bold"}     >{`Communauté`} </div>
              <div id='bloc10_0_0_1'       >{`Réseaux sociaux`} </div>
              <div id='bloc10_0_0_2'       >{`Parrainnage`} </div>
              <div id='bloc10_0_0_3'       >{`Forums privés`} </div></div>
            <div id='bloc10_0_1'  className={ " flex flex-col items-start p-4"}     > <div id='bloc10_0_1_0'  className={ "font-bold text-xl"}     >{`Solutions`} </div>
              <div id='bloc10_0_1_1'       >{`Communication`} </div>
              <div id='bloc10_0_1_2'       >{`Gestion clientèle`} </div>
              <div id='bloc10_0_1_3'       >{`Boutique en ligne`} </div>
              <div id='bloc10_0_1_4'       > </div></div>
            <div id='bloc10_0_2'  className={ " flex flex-col items-start p-4"}     > <div id='bloc10_0_2_0'  className={ "font-bold text-xl"}     >{`Mentions légales`} </div>
              <div id='bloc10_0_2_1'       >{`Conditions générales d'utilisation`} </div>
              <div id='bloc10_0_2_2'       >{`Conditions générales de vente`} </div>
              <div id='bloc10_0_2_3'       >{`Politique de confidentialité`} </div></div>
            <div id='bloc10_0_3'  className={ " flex flex-col items-start p-4"}     > <div id='bloc10_0_3_0'  className={ "text-3xl font-bold"}     >{`chris design`} </div>
              <div id='bloc10_0_3_1'  className={ "text-xl"}     >{`L'app c'est vous!`} </div>
              <div id='bloc10_0_3_2'  className={ "underline font-bold"}     >{`A propos`} </div>
              <div id='bloc10_0_3_3'  className={ "underline font-bold"}     >{`Contact`} </div></div></div></div></>
  
}

export default Component3Page;
