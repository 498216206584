// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component4Page() {
//useNavigate const navigate = useNavigate()


const [content,setContent]= useState( {} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
useEffect(()=>{  action_event_447_bloc2()   },[])

const action_447 =    ({TemplateConsent,setTemplateConsent,postTemplateConsent})=> {    
        try { 
        

window.alert("TEST OK")
}
        catch (error) {
        console.error(error);
        }
      };  

const action_event_447_bloc2 =  async(event)=>{

                            
                           
              
                          await action_447( { event }) 
              
                          };  

const action_453 =    ({Etat,setEtat,postEtat})=> {    
        try { 
        

setEtat(!Etat)
}
        catch (error) {
        console.error(error);
        }
      };  

const setEtatbloc2_1_1 =(value)=> setInternal("etat1",value);

const action_event_453_bloc2_1_1 =  async(event)=>{

                            
                           var Etat = content["etat1"];
              
                          await action_453( { Etat,event, setEtat:setEtatbloc2_1_1  }) 
              
                          };  

const action_454 =    ({Etat,setEtat,postEtat})=> {    
        try { 
        

setEtat(Etat!=="vert" ? "vert":"rose")
}
        catch (error) {
        console.error(error);
        }
      };  

const setEtatbloc2_1_2 =(value)=> setInternal("etat2",value);

const action_event_454_bloc2_1_2 =  async(event)=>{

                            
                           var Etat = content["etat2"];
              
                          await action_454( { Etat,event, setEtat:setEtatbloc2_1_2  }) 
              
                          };  

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full flex flex-row justify-between items-center safearea bg-black"}     > <img  id='bloc0_0'  className={ "w-10 h-10 rounded-xl m-2   object-cover"}  src="/profiles/ploy6/user.png"   alt="undefined" />
          <div id='bloc0_1'  className={ "font-bold text-base text-white"}     > </div>
          <div id='bloc0_2'  className={ "w-20  relative h-12 "}     > <FontAwesomeIcon  id='bloc0_2_0'  icon={ fa.faArrowRightToBracket}      className={ "p-4 text-white absolute"}  />
            <FontAwesomeIcon  id='bloc0_2_1'  icon={ fa.faHomeLgAlt}      className={ "p-4 text-white absolute"}  /></div></div><div id='bloc1'  className={ "w-full h-full bg-zinc-300  p-6 flex md:flex-row flex-col justify-center items-start text-zinc-900 py-8 relative m-40"}     > <img  id='bloc1_0'  className={ "w-full h-full absolute -z-50  opacity-50 object-fill    object-cover"}  src="null"   alt="undefined" />
          <div id='bloc1_1'  className={ "flex justify-between z-50"}     > <div id='bloc1_1_0'  className={ "flex  flex-col w-1/2 justify-center  items-center"}     > <div id='bloc1_1_0_0'  className={ "flex justify-center items-center text-4xl font-bold text-left my-2 px-4"}     >{`FaciaTherapie`} </div>
              <div id='bloc1_1_0_1'  className={ "flex justify-center  items-center text-4xl font-bold  my-2 px-4"}     >{`un soin innovant pour soigner`} </div></div>
            <div id='bloc1_1_1'  className={ "flex flex-col justify-start items-start w-1/2"}     > <div id='bloc1_1_1_0'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`L’anxiété, le stress ou la fatigue`} </div>
              <div id='bloc1_1_1_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`Les douleurs chroniques telles que les lombalgies et les cervicalgies, les rhumatismes, l’arthrose, la fibromyalgie `} </div>
              <div id='bloc1_1_1_2'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`Les lumbagos, les tendinites ou les torticolis `} </div>
              <div id='bloc1_1_1_3'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les troubles du transit `} </div>
              <div id='bloc1_1_1_4'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les céphalées, migraines et acouphènes`} </div>
              <div id='bloc1_1_1_5x0'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les règles douloureuses.`} </div>
      <div id='bloc1_1_1_5x1'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les règles douloureuses.`} </div>
      <div id='bloc1_1_1_5x2'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les règles douloureuses.`} </div></div></div></div><div id='bloc2'  className={ "w-full flex flex-col justify-center items-center w-full flex flex-col justify-center items-center py-4 safearea-top"}    onStart = { action_event_447_bloc2} > <div id='bloc2_0'  className={ "h-40  w-full bg-orange-400  bg-primary_color"}     > </div>
          <div id='bloc2_1'  className={ "   -mt-32 -mb-32    md:-my-20 z-10 flex flex-col items-center justify-center md:flex-row"}     > <div id='bloc2_1_0'  className={ "bg-white  w-40 h-40 z-10 rounded-xl p-2"}     > <img  id='bloc2_1_0_0'  className={ "w-full h-full rounded-full opacity-50   object-cover"}  src="undefined"   alt="undefined" /></div>
            <div id='bloc2_1_1'  className={ " py-2 bg-zinc-900 flex flex-row items-center rounded-lg border-white"}    onClick = { action_event_453_bloc2_1_1} > <FontAwesomeIcon  id='bloc2_1_1_0'  icon={ fa.faDownload}      className={ "w-8 h-8 text-white"}  />
              <div id='bloc2_1_1_1'  className={ "px-1 flex flex-col justify-center items-center  "}     > <div id='bloc2_1_1_1_0'  className={ "text-white text-xs"}     >{`Install with`} </div>
                <div id='bloc2_1_1_1_1'  className={ "text-white text-xl font-extrabold"}     >{`AppIsYou`} </div></div></div>
            <div id='bloc2_1_2'  className={ " py-2 bg-zinc-900 flex flex-row items-center rounded-lg border-white"}    onClick = { action_event_454_bloc2_1_2} > <FontAwesomeIcon  id='bloc2_1_2_0'  icon={ fa.faDownload}      className={ "w-8 h-8 text-white"}  />
              <div id='bloc2_1_2_1'  className={ "px-1 flex flex-col justify-center items-center  "}     > <div id='bloc2_1_2_1_0'  className={ "text-white text-xs"}     >{`Install with`} </div>
                <div id='bloc2_1_2_1_1'  className={ "text-white text-xl font-extrabold"}     >{`AppIsYou`} </div></div></div></div>
          <div id='bloc2_2'  className={ "h-40  w-full bg-blue-200 bg-secondary_color"}     > </div></div><div id='bloc3'  className={ "w-full"}     > <div id='bloc3_0'  className={ "w-full h-40 p-4"}     > <div id='bloc3_0_0'  className={ " w-32 h-32 rounded-xl border-2 border-white"}     > </div></div>
          <div id='bloc3_1'  className={ "flex flex-col text-sx p-2 my-2 shadow-md rounded-lg bg-zinc-800 w-full min-h-[100vh] bg-center"}     > <div id='bloc3_1_0'  className={ "text-2xl text-white p-4 font-bold w-full text-left"}     > </div>
            <div id='bloc3_1_1'  className={ "text-base text-white p-4"}     > </div></div></div><div id='bloc4'  className={ "w-full p-2"}     > <div id='bloc4_0'  className={ "w-full p-4 rounded-xl"}     > <div id='bloc4_0_0'  className={ "flex flex-col text-sx p-2 my-2 shadow-md rounded-lg bg-zinc-800 w-full min-h-[100vh] bg-center bg-opacity-50"}     > <div id='bloc4_0_0_0'  className={ "text-2xl text-white p-4 font-bold w-full text-left"}     > </div>
              <div id='bloc4_0_0_1'  className={ "text-base text-white p-4 text-left"}     > </div></div></div></div><div id='bloc5'  className={ "w-full"}     > <div id='bloc5_0'  className={ "font-bold text-2xl"}     >{`Ceci est une carte `} </div>
          <map id='bloc5_1'  className={ "w-full h-80   bg-orange-500"}     > </map></div><div id='bloc6'  className={ "w-full bg-black bg-primary_color flex flex-row justify-center fixed bottom-0 left-0 text-white z-50 rounded-t-xl h-40"}     > <div id='bloc6_0'  className={ "    p-2 h  flex flex-row  gap-1 text-white w-full items-center justify-center"}     > <div id='bloc6_0_0'  className={ "p-2 flex flex-col"}     > <div id='bloc6_0_0_0'  className={ "text-xl p-1"}     > </div>
              <div id='bloc6_0_0_1'  className={ "text-xs"}     > </div></div></div>
          <div id='bloc6_1'       > </div></div></>
  
}

export default Component4Page;
